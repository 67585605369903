
import React, {  } from 'react';
import { Input, Button, Form } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import './auth.less';
import {apiLogin} from "../../api/auth";

const FormItem = Form.Item;

const Login = (props) => {
    const onFinish = ({password = ''}) => {
        apiLogin(password).then(res => {
            if (res.code === 0) {
                localStorage.setItem('userInfo', JSON.stringify({
                    token: res.data.accessToken,
                    nickName: res.data.user.nickName,
                    avatar: res.data.user.avatarUrl,
                }));

                window.location.replace('/#/');
            }
        });
    };

    return (
        <div className="page-login absolute absolute-fill flex items-center justify-center">

            <div className="login-panel-wrap">

                <div className="tab-wrap flex tc">
                    <div className="title flex items-center justify-center">
                        <i className="logo"></i>
                        <div className="flex flex-column items-center">
                            <p className="fs-18 lh-sm">控制中心</p>
                            <p className="fs-12 text-black-secondary lh-sm">登录控制中心</p>
                        </div>
                    </div >
                </div>

                {
                    <Form
                        name="normal_login"
                        className="login_form"
                        onFinish={onFinish}
                    >
                        <FormItem
                            name="password"
                            style={{paddingTop: 10}}
                            rules={[{ required: true, message: '请输入密码!' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="密码"
                                size="large"
                            />
                        </FormItem>

                        <div style={{paddingTop: 20,}}>
                            <Button size="large" block type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </div>
                    </Form>
                }
            </div>
        </div>
    );
};

export default Login;