// 标书管理接口
import $fetch from "./fetch";

// 新增标书
export const apiBidBatch = ({ csv }) => {
    return $fetch('/bids/batch', {
        method: 'POST',
        params: {
            csv,
        }
    });
}

// 新增标书
export const apiBidAdd = ({ bidSequence, charge, idCard, partnerID, password, price}) => {
    return $fetch('/bids/bid', {
        method: 'POST',
        params: {
            bidSequence,
            charge,
            idCard,
            partnerID,
            password,
            price
        }
    });
}

// 更新标书
export const apiBidUpdate = ({ id, bidSequence, charge, idCard, partnerName, password, price}) => {
    return $fetch(`/bids/bid/${id}`, {
        method: 'PUT',
        params: {
            bidSequence,
            charge,
            idCard,
            partnerName,
            password,
            price
        }
    });
}

// 标书列表
export const apiBidList = ({ blurSearch = false, machineID, bidId, paramKey, partnerName, paramValues, sequence, pageIndex = 1, pageSize = 10 } = {blurSearch: false, pageIndex: 1, pageSize: 10}) => {
    return $fetch('/bids/page', {
        method: 'GET',
        params: {
            blurSearch,
            machineID,
            bidId,
            partnerName,
            paramKey,
            paramValues,
            sequence,
            pageIndex,
            pageSize,
        }
    });
};


// 标书删除
export const apiBidDel = ({ id }) => {
    return $fetch(`/bids/bid/${id}`, {
        method: 'DELETE',
    });
}

// 标书详情
export const apiBidDetail = ({id}) => {
    return $fetch(`/bids/detail/${id}`, {
        method: 'GET',
    });
}

// 一键分配
export const apiBidAllocation = ({id}) => {
    return $fetch(`/bids/${id}/allocation`, {
        method: 'PUT',
    });
}

// 分配机器
export const apiBidMachineAllocation = ({id}) => {
    return $fetch(`/bids/${id}/machine/allocation`, {
        method: 'PUT',
    });
}

// 分配代理
export const apiBidProxyAllocation = ({id}) => {
    return $fetch(`/bids/${id}/proxy/allocation`, {
        method: 'PUT',
    });
}

// 分配标书
export const apiBidStrategyAllocation = ({id}) => {
    return $fetch(`/bids/${id}/strategy/allocation`, {
        method: 'PUT',
    });
}

// 标书删除
export const apiBidClear = ({ id }) => {
    return $fetch(`/bids/${id}/runtime`, {
        method: 'DELETE',
    });
}

// 标书删除
export const apiBidsClear = () => {
    return $fetch(`/bids/runtime`, {
        method: 'DELETE',
    });
}

// 清空系统数据
export const apiClearData = ({bid = true, machine = true, proxy = true, strategy = true} = {
    bid: true,
    machine: true,
    proxy: true,
    strategy: true
}) => {
    return $fetch(`/system/data`, {
        method: 'DELETE',
        params: {
            bid,
            machine,
            proxy,
            strategy,
        }
    });
};