
import React, { useEffect, useState } from 'react';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';


import {
    Breadcrumb, Button, Col,
    Form, Input, message,
    Switch,
    Spin,
} from "antd";
import {usePageViews} from "../../App";
import {apiGetSetting, apiSettingUpdate} from "../../api/settings";
// import {apiGetAutoDistribute, apiSetAutoDistribute} from "../../api";

const TextArea = Input.TextArea;

const Settings = () => {
    usePageViews();

    const [form] = Form.useForm();

    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {

        setPageLoading(true);

        try {
            const res = await apiGetSetting();

            setPageLoading(false);

            const {loginUrl = '', enableProxy = false, circleProxy = false, bidder = '', production = false, autocode = false, fakecode = false, takeover = false, running = false, delay = 0, coders = 0, consoleVersion = '', clientVersion = ''} = res.data;

            form.setFieldsValue({
                loginUrl,
                enableProxy,
                circleProxy,
                production,
                autocode,
                fakecode,
                takeover,
                running,
                delay,
                coders,
                consoleVersion,
                clientVersion,
                bidder,
            });

        } catch (e) {
            setPageLoading(false);
        }
    }

    const handleTestModeToggle = async (status) => {
        // const res = await apiSetAutoDistribute(status ? 1 : 0);
        //
        // message.success(`已${status ? '开启' : '关闭'}测试模式`);
        //
        // setIsTestMode(status);
    };

    const onFinish = async (
        {
            loginUrl,
            enableProxy,
            circleProxy,
            production,
            autocode,
            fakecode,
            takeover,
            running,
            delay,
            coders,
            consoleVersion,
            clientVersion,
            bidder,
        }
    ) => {

        // const clear = message.loading("配置更新中...");

        try {
            await apiSettingUpdate({
                loginUrl,
                enableProxy,
                circleProxy,
                production,
                autocode,
                fakecode,
                takeover,
                running,
                delay,
                coders,
                consoleVersion,
                clientVersion,
                bidder,
            });

            // clear();

            message.success("配置更新成功");
        } catch (e) {
            // clear();
        }

    };

    return <div className="page-settings">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                    <SettingOutlined />
                    <span>应用配置</span>
                </Breadcrumb.Item>
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">
            <Form
                style={{margin: '20px auto', maxWidth: 800}}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 19, offset: 1 }}
                layout="horizontal"
                form={form}
                onFinish={onFinish}
                className="control-form"
                initialValues={{
                    loginUrl: '',
                    enableProxy: false,
                    production: false,
                    autocode: false,
                    circleProxy: true,
                    fakecode: false,
                    takeover: false,
                    running: false,
                    delay: 5,
                    coders: 0,
                    consoleVersion: '',
                    clientVersion: '',
                }}
            >
                {
                    pageLoading ? <Spin className="spin" tip="加载中...">
                    </Spin> : <>
                        <Form.Item
                            label="登录地址"
                            name="loginUrl"
                            rules={[{ required: true, message: '登录地址不能为空' }]}
                        >
                            <Input
                                autoComplete="off"
                                placeholder="输入登录地址"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Relay模式"
                            name="enableProxy"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="循环代理"
                            name="circleProxy"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="生产模式"
                            name="production"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="自动打码"
                            name="autocode"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="模拟打码"
                            name="fakecode"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="托管模式"
                            name="takeover"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="是否运行"
                            name="running"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        <Form.Item
                            label="启动延时"
                            name="delay"
                        >
                            <Input
                                type="number"
                                placeholder="输入启动延时(s)"
                            />
                        </Form.Item>

                        <Form.Item
                            label="码工数量"
                            name="coders"
                        >
                            <Input
                                type="number"
                                placeholder="输入每台服务器上的码工数量(s)"
                            />
                        </Form.Item>

                        <Form.Item
                            label="控制台版本"
                            name="consoleVersion"
                        >
                            <Input
                                placeholder="输入控制台版本号"
                            />
                        </Form.Item>

                        <Form.Item
                            label="客户端版本"
                            name="clientVersion"
                        >
                            <Input
                                placeholder="输入客户端版本号"
                            />
                        </Form.Item>

                        <Form.Item
                            label="本地标书与策略"
                            name="bidder"
                        >
                            <TextArea
                                placeholder="输入本地标书和策略配置"
                            />
                        </Form.Item>

                        <Button className="btn-submit" type="primary" htmlType="submit" shape="round">
                            更新
                        </Button>
                    </>
                }

            </Form>

        </div>
    </div>;
};

export default Settings;