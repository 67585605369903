
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, message, Modal} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, NodeExpandOutlined, WindowsOutlined, ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiMachineAllocation, apiMachineAllocationDel, apiMachineDel, apiMachineList} from "../../api/machine";
import {apiProxyAllocationDel, apiProxyDel} from "../../api/proxy";
// import {apiPCList} from "../../api";
import { withRouter } from 'react-router-dom';

const { Column, ColumnGroup } = Table;

const PCList = (props) => {

    usePageViews();

    useEffect(() => {
        getMachineList({
            machineID: localStorage.getItem('pcId') || '',
            machineCode: '',
            pageIndex: 1,
        });
    }, []);

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const getMachineList = async ({
          machineID,
          machineCode,
        pageIndex = 1,
    }) => {
        const res = await apiMachineList({
            machineID,
            machineCode,
            pageIndex
        });

        localStorage.removeItem('pcId');

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        setTableData(res.data.lists.map((item, index) => ({...item.machine, bidClientStatus: item.bidClientStatus, bidCount: item.bidCount, key: index})));
    };

    const onFinish = ({ machineID = '', machineCode = '',}) => {
        getMachineList({
            machineID: machineID.trim(),
            machineCode: machineCode.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const machineID = form.getFieldValue('machineID') || '';
        const machineCode = form.getFieldValue('machineCode') || '';

        getMachineList({
            machineID: machineID.trim(),
            machineCode: machineCode.trim(),
            pageIndex: page,
        });
    };

    const handleMachineAllocation = async () => {
        await apiMachineAllocation();

        message.success('机器分配完毕');

        pageChange(1);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除机器',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该机器?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiMachineDel({id});

                message.success("机器已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    const handleMachineClear = () => {
        Modal.confirm({
            title: '清空机器',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清空已分配的机器?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiMachineAllocationDel();

                message.success("机器已清空");

                pageChange(1);

                // getMachineList({
                //     pageIndex: 1,
                // });
            },
            // onCancel() {
            // },
        });
    };

    const getColor = (count) => {
        count = parseInt(count);

        if (count <= 0) {
            return '#0cb765';
        }

        if (count >= 1000) {
            return '#ffc300';
        }

        if (count > 0) {
            return '#ff4500';
        }
    };

    const handleGoToBid = (id) => () => {
        console.log(id);

        localStorage.setItem('bidId', id);
        props.history.push('/bid-list');

    }

    return <div className="page-home page-pc-list">
        <Header>
            <div className="flex justify-between">

                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <WindowsOutlined />
                        <span>机器管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

            </div>

        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>

                        <Col span={8}>
                            <Form.Item
                                name="machineID"
                                label="机器ID"
                            >
                                <Input placeholder="输入机器ID" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="machineCode"
                                label="机器编号"
                            >
                                <Input placeholder="输入机器编号" />
                            </Form.Item>
                        </Col>

                        <Col span={8} className="flex justify-between">
                            <div>
                                <Button type="primary" htmlType="submit">
                                    搜索
                                </Button>
                                <Button
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        form.resetFields();
                                        pageChange(1)
                                    }}
                                >
                                    重置
                                </Button>
                            </div>

                        </Col>
                    </Row>
                </Form>

                <Button
                    type="primary"
                    icon={<NodeExpandOutlined />}
                    onClick={handleMachineAllocation}
                    style={{margin: '-5px 0 20px'}}
                >
                    分配机器
                </Button>

                <Button
                    type="primary"
                    icon={<MinusCircleOutlined />}
                    onClick={handleMachineClear}
                    danger
                    style={{margin: '-5px 0 20px 20px'}}
                >
                    清空机器
                </Button>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 50,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="机器ID" dataIndex="id" key="id" />
                    <Column title="主机名" dataIndex="hostName" key="hostName" />
                    <Column title="序号" dataIndex="order" key="order" />
                    <Column title="机器编号" dataIndex="machineCode" key="machineCode" />
                    <Column title="地址" dataIndex="address" key="address" />
                    <Column title="容量" dataIndex="capacity" key="capacity" />

                    <Column title="标书数量" dataIndex="bidCount" key="bidCount" />

                    <Column title="故障量" dataIndex="droppedCount" key="droppedCount" render={(value, record) => {
                        let count = 0;

                        const netWorkStatus = record.bidClientStatus ? record.bidClientStatus.split(";") : [];

                        netWorkStatus.forEach(item => {
                            let errorCount = parseInt(item.split(",")[1]);

                            if (errorCount > 0) {
                                count = count + 1;
                            }
                        });

                        return count;

                    }} />

                    <Column title="详情" width={250} dataIndex="bidClientStatus" key="bidClientStatus" render={(value, record) => value ? value.split(";").map(item => <div key={item}>
                        <Tag className="db drop-detail pointer" onClick={handleGoToBid(item.split(",")[0])} color={getColor(item.split(",")[1])}>{item.split(",")[0]} <i>{item.split(",")[1]}</i></Tag>
                    
                    </div>) : ''} /> 

                    <Column title="状态"
                            dataIndex="online"
                            key="online"
                            render={
                                (text, record) => <span className={text ? 'text-success' : ''}>{text ? '在线' : '离线'}</span>
                            }
                    />

                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <a className="text-primary">编辑</a>
                                <a className="text-error" onClick={handleDelBtnClick(record.id)}>删除</a>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>
    </div>;
};

export default withRouter(PCList);