
import React, { FormEvent, useState, useEffect } from 'react';

import {Breadcrumb, Menu} from 'antd';
import { HomeOutlined, UserOutlined, HistoryOutlined, StockOutlined, TeamOutlined, DeploymentUnitOutlined, SettingOutlined, FileTextOutlined, CodepenOutlined, WindowsOutlined, NodeExpandOutlined } from '@ant-design/icons';
import {Link, useLocation} from "react-router-dom";

const { SubMenu } = Menu;

const  rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const SiderMenu = () => {

    const [activeMenu, setActiveMenu] = useState('');

    const location = useLocation();

    // useEffect(() => {
    //
    // }, [location]);

    return <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
    >
        <Menu.Item key="/">
            <Link to="/">
                <HomeOutlined />
                首页
            </Link>
        </Menu.Item>
        <Menu.Item key="/bid-list">
            <Link to="/bid-list">
                <FileTextOutlined  />
                标书管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/strategy-settings">
            <Link to="/strategy-settings">
                <CodepenOutlined />
                策略配置
            </Link>
        </Menu.Item>
        <Menu.Item key="/strategy-list">
            <Link to="/strategy-list">
                <DeploymentUnitOutlined />
                策略管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/pc-list">
            <Link to="/pc-list">
                <WindowsOutlined />
                机器管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/proxy-list">
            <Link to="/proxy-list">
                <NodeExpandOutlined />
                代理管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/partner-list">
            <Link to="/partner-list">
                <TeamOutlined  />
                友商管理
            </Link>
        </Menu.Item>
        <Menu.Item key="/settings">
            <Link to="/settings">
                <SettingOutlined />
                应用配置
            </Link>
        </Menu.Item>
        <Menu.Item key="/personal">
            <Link to="/personal">
                <UserOutlined />
                个人中心
            </Link>
        </Menu.Item>
        <Menu.Item key="/charts">
            <Link to="/charts">
                <StockOutlined />
                图表
            </Link>
        </Menu.Item>
    </Menu>;
};


export default SiderMenu;