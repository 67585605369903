
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Select, Switch, Modal, message, Upload, Drawer, Divider,  } from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, FileTextOutlined, DeleteOutlined, SendOutlined, MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined, CompassOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiBidSearchParam, apiWorkerList} from "../../api";
import {
    apiBidAdd, apiBidAllocation,
    apiBidBatch, apiBidClear,
    apiBidDel,
    apiBidDetail,
    apiBidList,
    apiBidMachineAllocation, apiBidProxyAllocation, apiBidsClear, apiBidStrategyAllocation,
    apiBidUpdate, apiClearData
} from "../../api/bid";
import {apiPartnerList} from "../../api/partner";
import {apiProxyAllocationDel} from "../../api/proxy";
import { withRouter } from 'react-router-dom';

const { Column, ColumnGroup } = Table;

const { Option } = Select;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);


const BidList = (props) => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [statusMap, setStatusMap] = useState([]);

    const [statusValArr, setStatusValArr] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [fileList, setFileList] = useState([]);

    const [bidErrArr, setBidErrArr] = useState([]);

    const [errCount, setErrCount] = useState(0);

    const [fieldKey, setFieldKey] = useState(0);

    const [fieldFocus, setFieldFocus] = useState(false);

    const [drawerShow, setDrawerShow] = useState(false);

    const [editId, setEditId] = useState('');

    const [blurSearch, setBlurSearch] = useState(false);

    const [partnerList, setPartnerList] = useState([]);

    const [bidData, setBidData] = useState(null);

    useEffect(() => {
        getBidList({
            pageIndex: 1,
        });

        apiBidSearchParam().then(res => {
            setStatusMap(res.data);
        });

        apiPartnerList({pageSize: 100}).then(res => {
            setPartnerList(res.data.lists);
        });

    }, []);

    useEffect(() => {

        if (editId) {
            const {bidSequence, password, charge, price, idCard, partnerName} = tableData.find(item => item.bidID == editId);

            formNew.setFieldsValue({
                bidNumber: bidSequence,
                password,
                price,
                charge,
                cert: idCard,
                partnerName,
            });
        }

    }, [editId]);

    useEffect(() => {

        if (!bidErrArr.length) {
            return;
        }

        Modal.error({
            title: '数据校验失败',
            width: 800,
            okText: "确定",
            content: <div className="lh-lg">
                {
                    bidErrArr.map(item => <div key={item.index} className="flex items-center">
                        <p className="tr" style={{width: 75}}>第 <span className="text-error">{item.index + 1}</span> 行</p>
                        <p className="flex-grow-1 w-0 ellipsis" style={{padding: '0 15px'}}>数据： {item.data.join(',')}</p>
                        <p style={{width: 200}}>原因： <span className="text-error">{item.message}</span></p>
                    </div>)
                }
                {
                    errCount > 15 && <div style={{paddingLeft: 10}}>
                        <p>...</p>
                        <p>总计 <span className="text-error">{errCount}</span> 个错误</p>
                    </div>
                }
            </div>,
        });
    }, [bidErrArr]);

    const getBidList = async (
        {
            blurSearch,
            machineID,
            bidId,
            partnerName,
            sequence,
            paramKey, paramValues,
            pageIndex,
            pageSize,
        }
    ) => {

        if (localStorage.getItem("bidId")) {
            bidId = localStorage.getItem("bidId");
        }

        const res = await apiBidList({
            blurSearch,
            machineID,
            bidId,
            partnerName,
            sequence,
            pageIndex,
            pageSize: pageSize || 100,
            paramKey,
            paramValues,
        });

        localStorage.removeItem("bidId");

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        setTableData(res.data.lists.map((item, index) => ({...item.bid, bidding: item.bidding, bidClientStatus: item.bidClientStatus, fodder: item.fodder, loginState: item.loginState, machineID: item.machineID, proxyID: item.proxyID, strategyID: item.strategyID, priceState: item.priceState, result: item.result, key: index})));
    };

    const onFinish = ({bid = '', status_key = '', status_val =''}) => {

        bid = bid || '';

        getBidList({
            blurSearch,
            machineID: fieldKey == 2 ? bid.trim() : '',
            bidId: fieldKey == 3 ? bid.trim() : '',
            partnerName: fieldKey == 1 ? bid.trim() : '',
            sequence: fieldKey == 0 ? bid.trim() : '',
            paramKey: status_key,
            paramValues: status_val,
            pageIndex: 1,
            pageSize: 100,
        });
    };

    const pageChange = (page) => {
        const bid = form.getFieldValue('bid') || '';
        let status_key = form.getFieldValue('status_key');
        let status_val = form.getFieldValue('status_val');

        status_key = status_key === 0 ? 0 : (status_key || '');
        status_val = status_val === 0 ? 0 : (status_val || '');

        getBidList({
            blurSearch,
            machineID: fieldKey == 2 ? bid.trim() : '',
            partnerName: fieldKey == 1 ? bid.trim() : '',
            sequence: fieldKey == 0 ? bid.trim() : '',
            paramKey: status_key,
            paramValues: status_val,
            pageIndex: page,
            pageSize: 100,
        });
    };

    const handleStatusKeyChange = (value) => {
        setStatusValArr((statusMap.find(item => item.key === value) || {}).params || []);
        form.setFieldsValue({status_val: null});
    };

    const handleModalOk = async () => {
        try {
            const { bidNumber, password, price, charge, cert, partnerName } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiBidUpdate({
                    id: editId,
                    bidSequence: bidNumber,
                    charge,
                    idCard: cert,
                    partnerName,
                    password: password,
                    price
                });

                setEditId(null);

                pageChange(pageIndex);
            } else {
                await apiBidAdd({
                    bidSequence: bidNumber,
                    charge,
                    idCard: cert,
                    partnerName,
                    password: password,
                    price
                });

                getBidList({
                    pageIndex: 1,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`标书${editId ? '更新' : '添加'}成功`);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setModalVisible(false);

        setEditId(null);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            // if (file.type !== 'image/png') {
            //     message.error(`${file.name} is not a png file`);
            // }

            const reader = new FileReader();

            setErrCount(0);

            reader.onload = async function () {
                // 对标号信息进行校验

                let csv = this.result;

                try {
                    const bidArr = this.result.split('\n').map(item => item.replace(/\s*/g, '').split(','));

                    const _bidErrArr = bidArr.reduce((total, item, index, arr) => {

                        // 第一行为表头
                        if (index === 0 && !/\d+/g.test(item[0])) {
                            return total;
                        }

                        if (!item[0]) {
                            return total;
                        }

                        if (item.length === 1 && item[0]) {

                            setErrCount(prevState => prevState+1);

                            if (total.length < 15) {
                                return total.concat({
                                    index,
                                    message: "标号和密码字段不能为空",
                                    data: item,
                                });
                            } else {
                                return total;
                            }
                        }

                        const validateArr = [
                            {
                                index: 0,
                                regex: /^\d{8}$/,
                                message: "标号信息格式错误",
                            },
                            {
                                index: 1,
                                regex: /^\d{4}$/,
                                message: "密码缺失或格式错误",
                                require: true,
                            },
                            {
                                index: 3,
                                regex: /^\d{1,5}$/,
                                message: "价格信息格式错误",
                            },
                            {
                                index: 4,
                                regex: /^\d{1,4}$/,
                                message: "单费信息格式错误",
                            },
                            {
                                index: 5,
                                regex: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                                message: "友商为空或格式不正确",
                                require: true,
                            },
                        ];

                        for (let i = 0; i < validateArr.length; i++) {
                            const _blk = validateArr[i];
                            if (item[_blk.index] && !_blk.regex.test(item[_blk.index]) || (!item[_blk.index] && _blk.require)) {
                                setErrCount(prevState => prevState+1);

                                if (total.length < 15) {
                                    return total.concat({
                                        index,
                                        message: _blk.message,
                                        data: item,
                                    });
                                } else {
                                    return total;
                                }
                            }
                        }

                        return total;
                    }, []);

                    setBidErrArr(_bidErrArr);

                    if (!_bidErrArr.length) {
                        // 上传标书信息

                        await apiBidBatch({csv});

                        message.success("标号导入成功");

                        getBidList({pageIndex: 1});
                    }
                } catch (e) {
                    message.error("文件内容解析失败,请查看文件内容和编码是否符合要求");
                }
            }

            reader.readAsText(file);

            return false;
        },
        onChange: info => {
            // console.log(info.fileList);
            setFileList(info.fileList);
        },
    };

    const handleFieldKeyChange = (key) => {
        setFieldKey(key);
        form.setFieldsValue({
            bid: null,
        });
    };

    const selectBefore = (
        <Select
            defaultValue="标号"
            style={{width: 100}}
            onChange={handleFieldKeyChange}
        >
            <Option value="0">标号</Option>
            <Option value="1">友商昵称</Option>
            <Option value="2">机器ID</Option>
            <Option value="3">标书ID</Option>
        </Select>
    );

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除标书',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该标书?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiBidDel({id});

                message.success("标书已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    const handleClearStatus = (id) => async () => {

        Modal.confirm({
            title: '清空标书状态',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清空该标书状态?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiBidClear({id});

                message.success("标书状态已清空");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    const handleBidsClear = async () => {

        Modal.confirm({
            title: '清空所有标书状态',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清空所有标书状态?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiBidsClear();

                message.success("所有标书状态已清空");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    const handleShowDetail = (id) => async () => {

        const res = await apiBidDetail({id});

        setBidData(res.data);

        setDrawerShow(true);
    };

    const handleQuickAllocation = (id) => async () => {
        const res = await apiBidAllocation({id});

        console.log(res);

        message.success("快速分配成功");

        const index = tableData.findIndex(item => item.bidID == id);

        setTableData(prev => {
            const arr = [...prev];

            arr.splice(index, 1, {
                ...arr[index],
                machineID: res.data.machineID,
                proxyID: res.data.proxyID,
                strategyID: res.data.strategyID,
            })

            return arr;
        });
    }

    const handleMachineAllocation = (id) => async () => {
        const res = await apiBidMachineAllocation({id});

        message.success("机器分配成功");

        const index = tableData.findIndex(item => item.bidID == id);

        setTableData(prev => {
            const arr = [...prev];

            arr.splice(index, 1, {
                ...arr[index],
                machineID: res.data.id,
            })

            return arr;
        });

    }

    const handleProxyAllocation = (id) => async () => {
        const res = await apiBidProxyAllocation({id});

        message.success("代理分配成功");

        const index = tableData.findIndex(item => item.bidID == id);

        setTableData(prev => {
            const arr = [...prev];

            arr.splice(index, 1, {
                ...arr[index],
                proxyID: res.data.id,
            })

            return arr;
        });
    }

    const handleStrategyAllocation = (id) => async () => {
        const res = await apiBidStrategyAllocation({id});

        message.success("策略分配成功");

        const index = tableData.findIndex(item => item.bidID == id);

        setTableData(prev => {
            const arr = [...prev];

            arr.splice(index, 1, {
                ...arr[index],
                strategyID: res.data.id,
            })

            return arr;
        });
    }

    const handleDataClear = () => {

        Modal.confirm({
            title: '清空系统数据',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清空所有系统中的数据?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                const res = await apiClearData();

                message.success('系统数据已清空');
            },
            // onCancel() {
            // },
        });

    };

    const handleGoTo = (type, id) => () => {
        console.log(type, id);

        switch (type) {
            case 'pc':
                localStorage.setItem('pcId', id);
                props.history.push('/pc-list');
                break;
            case 'proxy': 
                localStorage.setItem('proxyId', id);
                props.history.push('/proxy-list');
                break;
            case 'strategy':
                localStorage.setItem('strategyId', id);
                props.history.push('/strategy-list');
                break;
            default:
                break;
        }

    }

    const getColor = (count) => {
        count = parseInt(count);

        if (count <= 0) {
            return '#0cb765';
        }

        if (count >= 1000) {
            return '#ffc300';
        }

        if (count > 0) {
            return '#ff4500';
        }
    };

    return <div className="page-bid-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <FileTextOutlined />
                        <span>标书管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div>
                    <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        onClick={handleBtnNewClick}
                    >
                        录入标书
                    </Button>
                    <Upload
                        {...uploadProps}
                    >
                        <Button
                            style={{margin: '0 15px'}}
                            type="primary"
                            shape="round"
                            icon={<SendOutlined />}
                        >
                            导入标书
                        </Button>
                    </Upload>
                </div>
            </div>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >
                        <Col md={10} lg={8} style={{marginLeft: '-12px'}}>
                            <Form.Item
                                name="bid"
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="输入相应信息检索"
                                    addonBefore={selectBefore}
                                    suffix={<span className="text-black-secondary pointer" onClick={() => setBlurSearch(prevState => !prevState)}>{blurSearch ?  '模糊' : '精确'}</span>}
                                    onFocus={() => {
                                        setFieldFocus(true);
                                    }}
                                    onBlur={
                                        () => {
                                            setTimeout(() => {
                                                setFieldFocus(false);
                                            }, 200);
                                        }
                                    }
                                />
                            </Form.Item>
                            {
                                fieldKey == 1 && fieldFocus && <div className="drop-down-panel">
                                    {
                                        partnerList.map(partner => <li
                                            key={partner.id}
                                            onClick={
                                                () => {
                                                    form.setFieldsValue({bid: partner.name});
                                                }
                                            }
                                        >{partner.name}</li>)
                                    }
                                </div>
                            }
                        </Col>

                        <Col md={6} lg={4}>
                            <Form.Item
                                name="status_key"
                                // label="状态名"
                                // initialValue="all"
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    placeholder="请选择状态名"
                                    onChange={handleStatusKeyChange}
                                >
                                    {
                                        statusMap.map(item => <Option key={item.key} value={item.key}>{item.name}</Option>)
                                    }
                                    {/*<Option value="1">异常状态</Option>*/}
                                    {/*<Option value="2">机器分配</Option>*/}
                                    {/*<Option value="3">策略分配</Option>*/}
                                    {/*<Option value="4">代理分配</Option>*/}
                                    {/*<Option value="5">登录状态</Option>*/}
                                    {/*<Option value="6">出价状态</Option>*/}
                                    {/*<Option value="7">中标状态</Option>*/}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={8} lg={6} >
                            <Form.Item
                                name="status_val"
                                // label="状态值"
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear
                                    placeholder="选择状态值，可多选"
                                >
                                    {
                                        (statusValArr || []).map(item => <Option key={item.value} value={item.value}>{item.name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={8} lg={6} >
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getBidList({
                                        pageIndex: 1,
                                    });
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <div className="flex justify-between">
                    <Button
                        type="primary"
                        icon={<MinusCircleOutlined />}
                        onClick={handleBidsClear}

                        style={{margin: '-5px 0 20px -12px'}}

                        danger
                    >
                        清空状态
                    </Button>

                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={handleDataClear}

                        style={{margin: '-5px 0 20px -12px'}}

                        danger
                    >
                        清空系统数据
                    </Button>
                </div>

            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 100,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                    scroll={{ x: 1500 }}
                >
                    <Column title="序号" fixed='left' render={(text,record,index)=>`#${index+1}`} />

                    <Column title="标号" fixed='left' width={116} dataIndex="bidSequence" key="bidSequence" render={(text, record) => <div className="relative">
                        {text}
                        {
                            record.fodder ? <div className="fodder">炮</div> : ''
                        }
                    </div>} />
                    <Column title="密码" width={70} dataIndex="password" key="password" />
                    <Column title="证件号" width={180} dataIndex="idCard" key="idCard" />
                    <Column
                        title="异常信息"
                        dataIndex="checkStatus"
                        key="checkStatus"
                        render={
                            (text, record) => ['', '身份证格式', '标书号或密码', '都有问题'][parseInt(text || 0)]
                        }
                    />

                    <Column title="客户端状态"
                            render={
                                (text, record) => ['正常', '关闭', '报错', '失联', '预热失败', '报修'][record.bidding && record.bidding.clientStatus]
                            }
                    />

                    <Column title="登录状态"
                            dataIndex="loginState"
                            key="loginState"
                            render={
                                (text, record) => ["登录成功", "未登录", "正在登录", "登录失败", "证件号错误"][record.bidding && record.bidding.loginState]
                            }
                    />

                    <Column title="出价状态" dataIndex="priceState" key="priceState"
                            render={
                                (text, record) => record.bidding && record.bidding.bidCount != null ? <span>{record.bidding.bidCount + " / " + record.bidding.bidAmount + " / " + record.bidding.bidTime}</span> : ''
                            } />

                    <Column title="是否中标" dataIndex="result"
                            render={                                
                                (text, record) => record.bidding && record.bidding.winStatus != null ? <span className={record.bidding.winStatus ? 'text-success' : ''}>{record.bidding.winStatus ? '中' : '不'}</span> : ''
                            }
                    />
                    <Column title="友商昵称" dataIndex="partnerName" key="partnerName" />

                    <Column
                        title="操作"
                        key="action"
                        width={280}
                        fixed="right"
                        render={(text, record) => (
                            <Space size="middle">

                                <p className="pointer" onClick={handleShowDetail(record.bidID)}>详情</p>
                                <p className="text-primary pointer" onClick={handleEditBtnClick(record.bidID)}>编辑</p>
                                {/*<p className="text-error">禁用</p>*/}
                                <p className="text-error pointer" onClick={handleDelBtnClick(record.bidID)}>删除</p>
                                {/* <p className="pointer" onClick={handleClearStatus(record.bidID)}>清空</p> */}

                                {/* {
                                    !(record.strategyID && record.machineID && record.proxyID) && <p className="text-success pointer" onClick={handleQuickAllocation(record.bidID)}>快速分配</p>
                                } */}

                            </Space>
                        )}
                    />
                </Table>
            </div>

            <Modal
                title={editId ? '标书编辑' : '标书录入'}
                visible={modalVisible}
                onOk={handleModalOk}
                confirmLoading={modalConfirmLoading}
                onCancel={handleModalCancel}
                okText="确定"
                cancelText="取消"
                maskClosable={false}
            >
                <Form
                    form={formNew}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 17 }}
                    name="basic"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="标号"
                        name="bidNumber"
                        rules={[
                            {
                                required: true,
                                message: '请输入标号信息'
                            },
                            {
                                pattern: /^\d{8}$/,
                                message: '标号需为八位数字'
                            },
                        ]}
                    >
                        <Input autoComplete="off" maxLength={8} placeholder="输入标号" />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码'
                            },
                            {
                                pattern: /^\d{4}$/,
                                message: '密码需为四位数字'
                            },
                        ]}
                    >
                        <Input autoComplete="off" maxLength={4} placeholder="输入密码" />
                    </Form.Item>

                    <Form.Item
                        label="证件号"
                        name="cert"
                    >
                        <Input autoComplete="off" maxLength={18} placeholder="输入证件号" />
                    </Form.Item>

                    <Form.Item
                        label="价格"
                        name="price"
                        rules={[
                            {
                                pattern: /^\d{2,5}$/,
                                message: '请输入2-5位数字'
                            }
                        ]}
                    >
                        <Input autoComplete="off" type="number" maxLength={5} placeholder="输入价格" />
                    </Form.Item>

                    <Form.Item
                        label="单费"
                        name="charge"
                        rules={[{
                            pattern: /^\d{1,4}$/,
                            message: '请输入1-4位数字'
                        }]}
                    >
                        <Input autoComplete="off" type="number" maxLength={4} placeholder="输入单费" />
                    </Form.Item>

                    <Form.Item
                        label="友商"
                        name="partnerName"
                    >
                        <Input autoComplete="off" maxLength={10} placeholder="输入友商" />
                    </Form.Item>
                </Form>
            </Modal>

            <Drawer
                width={650}
                placement="right"
                // closable={false}
                onClose={() => {
                    setDrawerShow(false);
                    setBidData(null);
                }}
                visible={drawerShow}
            >
                {
                    bidData && <div>
                        <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                            标书详情【<span className="text-primary">{bidData.bidVO.bid.bidSequence}</span>】
                        </p>
                        <p className="site-description-item-profile-p">标书信息</p>
                        <Row>
                            <Col span={12}>
                                <DescriptionItem title="ID" content={bidData.bidVO.bid.bidID} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="标号" content={bidData.bidVO.bid.bidSequence} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <DescriptionItem title="密码" content={bidData.bidVO.bid.password} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="证件号" content={bidData.bidVO.bid.idCard} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="异常信息" content={['', '身份证格式', '标书号或密码', '都有问题'][parseInt(bidData.bidVO.bid.checkStatus)]} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="价格" content={bidData.bidVO.bid.price} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="单费" content={bidData.bidVO.bid.charge} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="友商" content={bidData.bidVO.bid.partnerName} />
                            </Col>
                        </Row>

                        <Divider />


                        {
                            bidData.bidVO.bidding && <div>
                            <p className="site-description-item-profile-p">拍牌信息</p>
                        
                            <Row>
                                <Col span={12}>
                                    <DescriptionItem title="客户端状态" content={['正常', '关闭', '报错', '失联', '预热失败', '报修'][bidData.bidVO.bidding.clientStatus]} />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem title="登录状态" content={["登录成功", "未登录", "正在登录", "登录失败", "证件号错误"][bidData.bidVO.bidding.loginState]} />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem title="出价状态" content={bidData.bidVO.bidding.bidCount != null ? bidData.bidVO.bidding.bidCount + " / " + bidData.bidVO.bidding.bidAmount + " / " + bidData.bidVO.bidding.bidTime : ''} />
                                </Col>
    
                                <Col span={12}>
                                    <DescriptionItem title="是否中标" content={bidData.bidVO.bidding.winStatus != null ? (bidData.bidVO.bidding.winStatus ? '中' : '不') : ''} />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem title="所属人" content={bidData.bidVO.bidding.ownerName} />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem title="已拍次数" content={bidData.bidVO.bidding.usedCount} />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem title="过期时间" content={bidData.bidVO.bidding.validDate} />
                                </Col>
                            </Row>
                            <Divider />
                            </div>                     
                        }

                        <p className="site-description-item-profile-p">运营配置</p>
                        <Row>
                            <Col span={12}>
                                <DescriptionItem title="机器ID" content={(bidData.machine || {}).id} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="机器编号" content={(bidData.machine || {}).machineCode} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="代理ID" content={(bidData.proxy || {}).id} />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem title="代理商" content={(bidData.proxy || {}).partnerName} />
                            </Col>
                        </Row>

                        <Divider />

                        {/*conf: {short: 14, force: 3, long: 35}*/}
                        {/*id: "1303556293272936448"*/}
                        {/*price: "13,600,100;14,800,0;15,700,100"*/}

                        <p className="site-description-item-profile-p">策略</p>

                        {
                            bidData.strategy ? <div>
                                <Row>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="策略ID"
                                            content={bidData.strategy.id}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="长伏击"
                                            content={bidData.strategy.conf.long + 's'}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="短伏击"
                                            content={bidData.strategy.conf.short + 's'}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="强制提交"
                                            content={bidData.strategy.conf.force + 's'}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <DescriptionItem
                                            title="价格表"
                                            content={bidData.strategy.price}
                                        />                                    </Col>
                                </Row>
                            </div> : <div className="text-black-secondary">暂无策略</div>
                        }
                    </div>
                }

            </Drawer>

        </div>

        <a
            href="https://code-sprite.oss-cn-shanghai.aliyuncs.com/bid.csv"
            className="demo-download-link">
            <Button shape="round" type="primary">
                <CompassOutlined />
                示例下载
            </Button>
        </a>

    </div>;
};

export default withRouter(BidList);