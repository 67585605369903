import React, { useEffect } from 'react';

import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from 'react-router-dom';

import {Layout} from "antd";

import PageHome from './pages/Home';
import PageLogin from './pages/Auth/Login';

import './assets/style/common.less';

import './App.less'

import Home from "./pages/Home";
import Login from "./pages/Auth/Login";

import SiderMenu from './components/SiderMenu';
import BidList from "./pages/BidList";
import StrategySettings from "./pages/StrategySettings";
import StrategyList from "./pages/StrategyList";
import ProxyList from "./pages/ProxyList";
import PCList from "./pages/PCList";
import PartnerList from "./pages/PartnerList";
import Personal from "./pages/Personal";
import Settings from "./pages/Settings";
import Charts from './pages/Charts';

const { Footer, Sider, Content } = Layout;

export const appTheme = 'light';

export const usePageViews = () => {
    let location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/login' && !localStorage.getItem('userInfo')) {
            window.location.href = '/#/login';
        }
    }, [location]);
}

const App = () => {

  return (
      <Router>
        <Switch>
            <Route path="/login">
                <Login />
            </Route>

            {/*<Route path="/personal">*/}
            {/*    <div>*/}
            {/*        个人中心*/}
            {/*    </div>*/}
            {/*</Route>*/}

            <Route path="/">
                <Layout className="app-layout">
                    <Sider className="app-sider" width={200} theme={appTheme}>
                        <div className="sider-header flex items-center">
                            <div className="logo"></div>
                            <div>
                                <p className="fs-18 lh-md">控制中心</p>
                                <p className="fs-12 lh-sm text-black-secondary">拍牌管理后台</p>
                            </div>
                        </div>
                        <SiderMenu />
                    </Sider>
                    <Layout className="app-layout-right">
                        <Content>
                            <Switch>
                                <Route path="/settings">
                                    <Settings />
                                </Route>
                                <Route path="/personal">
                                    <Personal />
                                </Route>
                                <Route path="/bid-list">
                                    <BidList />
                                </Route>
                                <Route path="/strategy-settings">
                                    <StrategySettings />
                                </Route>
                                <Route path="/strategy-list">
                                    <StrategyList />
                                </Route>
                                <Route path="/proxy-list">
                                    <ProxyList />
                                </Route>
                                <Route path="/pc-list">
                                    <PCList />
                                </Route>
                                <Route path="/partner-list">
                                    <PartnerList />
                                </Route>
                                <Route path="/charts">
                                    <Charts />
                                </Route>
                                <Route path="/">
                                    <Home />
                                </Route>
                            </Switch>
                        </Content>
                        <Footer theme={appTheme}>
                            © Copyright 合肥钧天科技有限责任公司
                        </Footer>
                    </Layout>
                </Layout>
            </Route>
        </Switch>
      </Router>
  );
};

export default App;
