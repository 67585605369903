import {message} from 'antd';

const noneValue = (method, value) => {
    if (value || (value === 0) || (value === false)) {
        return value;
    }

    if ((method === 'GET') || (method === 'DELETE')) {
        return '';
    } else {
        return null;
    }
};

const getRealParams = (params) => {
    let _params = {};

    Object.keys(params).forEach((key) => {
        if (params[key] || (params[key] === 0) || (params[key] === false)) {
            _params[key] = params[key];
        }
    });

    return _params;
}

let clearTimer = null;

const $fetch = (url, option = {}) => {
    const { method = 'GET', params={}, headers, resType = 'json', ...other } = option;

    const {token} = JSON.parse(localStorage.getItem('userInfo') || '{}');

    const realParams = getRealParams(params);

    !clearTimer && (clearTimer = message.loading("请求中...", 0));

    // 区分 GET 和 POST

    if ((method === 'GET') || (method === 'DELETE')) {
        let queryString = realParams ? Object.keys(realParams).map(key => `${key}=${noneValue(method, realParams[key])}`).join('&') : '';

        if (queryString && url.indexOf('?') === -1) {
            queryString = `?${queryString}`;
        }

        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_BASE_URL || ''}${url}${queryString}`, {
                method,
                credentials: 'include',
                headers: headers || {
                    'Content-Type': 'application/json',
                    access_token: token,
                },
                ...other,
            }).then(res => res[resType]()).then(resp => {

                clearTimer && clearTimer();
                clearTimer = null;

                if (resp.code === 3001) {
                    message.error('用户未登录或token已过期，请重新登录');
                    window.location.href = '/#/login';
                    localStorage.removeItem('userInfo');
                    reject({message: '用户未登录'});
                    return;
                }

                if (resp.code === 0) {
                    return resolve(resp);
                }

                resp.message && message.error(resp.message);

                reject(resp);

                return resolve(resp);
            }).catch(e => {
                clearTimer && clearTimer();
                clearTimer = null;
                reject(e);
            } );
        });
    }

    Object.keys(realParams).forEach(key => {
        realParams[key] = noneValue(method, realParams[key]);
    });

    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_BASE_URL || ''}${url}`, {
            method,
            body: JSON.stringify(realParams),
            credentials: 'include',
            headers: headers || {
                'Content-Type': 'application/json',
                access_token: token,
            },
            ...other,
        }).then(res => res[resType]()).then(resp => {
            clearTimer && clearTimer();
            clearTimer = null;

            if (resp.code === 3001) {
                message.error('用户未登录或token已过期，请重新登录');
                window.location.href = '/#/login';
                localStorage.removeItem('userInfo');
                reject({message: '用户未登录'});
                return;
            }

            if (resp.code === 0) {
                return resolve(resp);
            }

            resp.message && message.error(resp.message);

            reject(resp);

        }).catch(e => {
            console.log(e);
            clearTimer && clearTimer();
            clearTimer = null;

            e.message && message.error(e.message);
            reject(e);
        });
    });
};

export default $fetch;