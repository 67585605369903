
// 策略接口
import $fetch from "./fetch";

export const apiMachineList = ({pageIndex = 1, pageSize = 50, machineID = '', machineCode = '' }={pageIndex: 1, pageSize: 50}) => {
    return $fetch('/machines/page', {
        method: 'GET',
        params: {
            pageIndex,
            pageSize,
            machineID,
            machineCode,
        },
    });
};

// 分配机器
export const apiMachineAllocation = () => {
    return $fetch('/machines/allocation', {
        method: 'POST',
    });
};

// 清空机器
export const apiMachineAllocationDel = () => {
    return $fetch('/machines/allocation', {
        method: 'DELETE',
    });
};

// 删除机器
export const apiMachineDel = ({id}) => {
    return $fetch(`/machines/machine/${id}`, {
        method: 'DELETE',
    });
};
