
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Select, Switch, Modal, message, Upload, Drawer, Divider } from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, FileTextOutlined, SendOutlined, MinusCircleOutlined, CloseOutlined, CheckOutlined, CloseCircleOutlined, CompassOutlined, NodeIndexOutlined, PlusOutlined, ExclamationCircleOutlined, NodeExpandOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiBidSearchParam, apiWorkerList} from "../../api";
import {apiBidAdd, apiBidBatch, apiBidDel, apiBidList, apiBidUpdate} from "../../api/bid";
import {apiPartnerAdd, apiPartnerList, apiPartnerUpdate} from "../../api/partner";
import {
    apiProxiesClear,
    apiProxyAdd,
    apiProxyAllocation, apiProxyAllocationDel,
    apiProxyBatch,
    apiProxyDel, apiProxyFlyAdd,
    apiProxyList,
    apiProxyUpdate,
    apiToggleProxyStatus
} from "../../api/proxy";

import { withRouter } from 'react-router-dom';
import appConfig from '../../appConfig';

const { Column, ColumnGroup } = Table;

const { Option } = Select;


const ProxyList = (props) => {

    usePageViews();

    const [tableData, setTableData] = useState([]);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [formFly] = Form.useForm();

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalFlyVisible, setModalFlyVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    const [fileList, setFileList] = useState([]);

    const [bidErrArr, setBidErrArr] = useState([]);

    const [errCount, setErrCount] = useState(0);

    const [editId, setEditId] = useState('');

    useEffect(() => {
        getProxyList({
            proxyId: localStorage.getItem('proxyId') || '',
            pageIndex: 1,
        });

    }, []);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const { host, port, userName, password, capacity, partnerName } = tableData.find(item => item.id == editId);

        formNew.setFieldsValue({
            host,
            port,
            userName,
            password,
            capacity,
            partnerName
        });

    }, [editId]);

    useEffect(() => {

        if (!bidErrArr.length) {
            return;
        }

        Modal.error({
            title: '数据校验失败',
            width: 800,
            okText: "确定",
            content: <div className="lh-lg">
                {
                    bidErrArr.map(item => <div key={item.index} className="flex items-center">
                        <p className="tr" style={{width: 75}}>第 <span className="text-error">{item.index + 1}</span> 行</p>
                        <p className="flex-grow-1 w-0 ellipsis" style={{padding: '0 15px'}}>数据： {item.data.join(',')}</p>
                        <p style={{width: 200}}>原因： <span className="text-error">{item.message}</span></p>
                    </div>)
                }
                {
                    errCount > 15 && <div style={{paddingLeft: 10}}>
                        <p>...</p>
                        <p>总计 <span className="text-error">{errCount}</span> 个错误</p>
                    </div>
                }
            </div>,
        });
    }, [bidErrArr]);

    const getProxyList = async (
        {
            proxyId,
            partnerName,
            pageIndex,
        }
    ) => {
        const res = await apiProxyList({
            proxyId,
            partnerName,
            pageIndex,
            pageSize: 100,
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        localStorage.removeItem('proxyId');

        setTableData(res.data.lists.map((item, index) => ({...item.proxy, bidClientStatus: item.bidClientStatus, bidCount: item.bidCount, droppedCount: item.droppedCount, key: index})));
    };

    const onFinish = ({status = '', partnerName = '', proxyId = '' }) => {
        partnerName = partnerName || '';

        getProxyList({
            proxyId: proxyId.trim() || '',
            partnerName: partnerName.trim() || '',
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const status = form.getFieldValue('status') || '';
        const partnerName = form.getFieldValue('partnerName') || '';
        const proxyId = form.getFieldValue('proxyId') || '';

        getProxyList({
            proxyId: proxyId.trim() || '',
            partnerName: partnerName.trim() || '',
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        try {
            const { host, port, userName, password, capacity, partnerName } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiProxyUpdate({
                    id: editId,
                    host,
                    port: parseInt(port),
                    userName,
                    password,
                    capacity,
                    partnerName
                });

                setEditId(null);

                pageChange(pageIndex);
            } else {
                await apiProxyAdd({
                    host,
                    port: parseInt(port),
                    userName,
                    password,
                    capacity,
                    partnerName
                });

                getProxyList({
                    pageIndex: 1,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`代理${editId ? '更新' : '添加'}成功`);

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setModalVisible(false);

        setEditId(null);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            // if (file.type !== 'image/png') {
            //     message.error(`${file.name} is not a png file`);
            // }

            const reader = new FileReader();

            setErrCount(0);

            reader.onload = async function () {
                // 对标号信息进行校验

                let csv = this.result;

                try {
                    const bidArr = this.result.split('\n').map(item => item.replace(/\s*/g, '').split(','));

                    const _bidErrArr = bidArr.reduce((total, item, index, arr) => {

                        // 第一行为表头
                        if (index === 0 && !/\d+/g.test(item[0])) {
                            return total;
                        }

                        if (!item[0]) {
                            return total;
                        }

                        if (item.length === 1 && item[0]) {

                            setErrCount(prevState => prevState+1);

                            if (total.length < 15) {
                                return total.concat({
                                    index,
                                    message: "代理地址、端口、容量、供应商信息不能为空",
                                    data: item,
                                });
                            } else {
                                return total;
                            }
                        }

                        const validateArr = [
                            {
                                index: 0,
                                regex: /((25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)/,
                                message: "主机地址信息格式错误",
                            },
                            {
                                index: 1,
                                regex: /^\d{1,5}$/,
                                message: "端口不错在或格式错误",
                                require: true,
                            },
                            {
                                index: 4,
                                regex: /^\d{1,3}$/,
                                message: "容量不存在或格式错误",
                                require: true,
                            },
                            {
                                index: 5,
                                regex: /^[\.@:A-Za-z0-9\u4e00-\u9fa5]+$/,
                                message: "供应商为空或格式不正确",
                                require: true,
                            },
                        ];

                        for (let i = 0; i < validateArr.length; i++) {
                            const _blk = validateArr[i];
                            if (item[_blk.index] && !_blk.regex.test(item[_blk.index]) || (!item[_blk.index] && _blk.require)) {
                                setErrCount(prevState => prevState+1);

                                if (total.length < 15) {
                                    return total.concat({
                                        index,
                                        message: _blk.message,
                                        data: item,
                                    });
                                } else {
                                    return total;
                                }
                            }
                        }

                        return total;
                    }, []);

                    setBidErrArr(_bidErrArr);

                    if (!_bidErrArr.length) {
                        // 上传代理信息

                        await apiProxyBatch({csv});

                        message.success("代理导入成功");

                        getProxyList({pageIndex: 1});
                    }
                } catch (e) {
                    message.error("文件内容解析失败,请查看文件内容和编码是否符合要求");
                }
            }

            reader.readAsText(file);

            return false;
        },
        onChange: info => {
            // console.log(info.fileList);
            setFileList(info.fileList);
        },
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除代理',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该代理?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiProxyDel({id});

                message.success("代理已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    const handleProxyAllocation = async () => {
        await apiProxyAllocation();

        message.success('代理分配完毕');

        pageChange(1);
    };

    const handleAddFlyProxy = async () => {
        setModalFlyVisible(true);
    };

    const handleModalFlyOk = async () => {
        try {
            const { address, count } = await formFly.validateFields();

            setModalConfirmLoading(true);

            await apiProxyFlyAdd({
                address,
                count
            });

            getProxyList({
                pageIndex: 1,
            });

            formFly.resetFields();
            setModalFlyVisible(false);
            setModalConfirmLoading(false);

            message.success('飞猪代理添加成功');

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalFlyCancel = () => {
        setModalFlyVisible(false);

        formFly.resetFields();
    };

    const handleProxyClear = () => {
        Modal.confirm({
            title: '清空代理',
            icon: <ExclamationCircleOutlined />,
            content: '是否要清空已分配的代理?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiProxyAllocationDel();

                message.success("代理已清空");

                pageChange(1);

                // getProxyList({
                //     pageIndex: 1,
                // });
            },
            // onCancel() {
            // },
        });
    };

    const handleProxyDisabledClear = () => {
        Modal.confirm({
            title: '删除所有代理',
            icon: <ExclamationCircleOutlined  />,
            content: '是否要删除所有代理?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiProxiesClear();

                message.success("所有代理已删除");

                getProxyList({
                    pageIndex: 1,
                });
            },
            // onCancel() {
            // },
        });
    }

    const handleToggleStatus = (id) => (checked) => {
        console.log(id, checked);

        Modal.confirm({
            title: `切换代理状态`,
            icon: <ExclamationCircleOutlined />,
            content: <span className="fs-16">将代理状态切换为{checked ? <span className="text-primary">可用</span> : <span className="text-error">不可用</span>}?</span>,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiToggleProxyStatus({
                    id,
                    status: checked ? 0 : 1,
                });

                pageChange(pageIndex);

                message.success("代理状态已修改");

            },
            // onCancel() {
            // },
        });

    };

    const getColor = (count) => {
        count = parseInt(count);

        if (count <= 0) {
            return '#0cb765';
        }

        if (count >= 1000) {
            return '#ffc300';
        }

        if (count > 0) {
            return '#ff4500';
        }
    };

    const handleGoToBid = (id) => () => {
        console.log(id);

        localStorage.setItem('bidId', id);
        props.history.push('/bid-list');

    }

    return <div className="page-proxy-list">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <FileTextOutlined />
                        <span>代理管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div>
                    <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        onClick={handleBtnNewClick}
                    >
                        录入代理
                    </Button>
                    <Upload
                        {...uploadProps}
                    >
                        <Button
                            style={{margin: '0 15px'}}
                            type="primary"
                            shape="round"
                            icon={<SendOutlined />}
                        >
                            导入代理
                        </Button>
                    </Upload>
                    <Button
                        type="primary"
                        danger
                        shape="round"
                        icon={<NodeIndexOutlined />}
                        onClick={handleAddFlyProxy}
                    >
                        飞猪代理
                    </Button>
                </div>
            </div>
        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24} >

                        <Col span={8}>
                            <Form.Item
                                name="proxyId"
                                label="代理ID"
                            >
                                <Input placeholder="输入代理ID" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                name="partnerName"
                                label="供应商"
                            >
                                <Input placeholder="输入供应商名称" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                name="status"
                                label="状态"
                                initialValue="1"
                            >
                                <Select
                                    allowClear
                                    placeholder="请选择状态"
                                >
                                    <Option value="1">全部</Option>
                                    <Option value="2">可用</Option>
                                    <Option value="3">不可用</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={6} className="flex justify-between">
                            <div>
                                <Button type="primary" htmlType="submit">
                                    搜索
                                </Button>
                                <Button
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        form.resetFields();
                                        getProxyList({
                                            pageIndex: 1,
                                        });
                                    }}
                                >
                                    重置
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>

                <div className="flex justify-between">
                    <div>
                        <Button
                            type="primary"
                            icon={<NodeExpandOutlined />}
                            onClick={handleProxyAllocation}

                            style={{margin: '-5px 0 20px'}}
                        >
                            分配代理
                        </Button>

                        <Button
                            type="primary"
                            icon={<MinusCircleOutlined />}
                            onClick={handleProxyClear}
                            danger
                            style={{margin: '-5px 0 20px 20px'}}
                        >
                            清空代理
                        </Button>
                    </div>

                    <Button
                        type="primary"
                        icon={<CloseOutlined />}
                        onClick={handleProxyDisabledClear}
                        danger
                        style={{margin: '-5px 0 20px 20px'}}
                    >
                        删除所有代理
                    </Button>
                </div>

            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 100,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="端口" dataIndex="port" key="port" />
                    <Column title="在线" dataIndex="offline" key="offline" render={(value,record) =>  value ? <CloseCircleOutlined style={{ fontSize: '16px', color: '#f00' }} /> : <CheckOutlined style={{ fontSize: '16px', color: '#0f0' }} />} />
                    <Column title="IP" dataIndex="ip" key="ip" />
                    <Column title="线路" dataIndex="location" key="location" />
                    <Column title="机房延迟" dataIndex="delayAli" key="delayAli" />
                    <Column title="国拍延迟" dataIndex="delayBid" key="delayBid" />
                    <Column title="主机地址" dataIndex="host" key="host" />
                    <Column title="用户名" dataIndex="userName" key="userName" />
                    <Column title="密码" dataIndex="password" key="password" />
                    <Column title="容量" dataIndex="capacity" key="capacity" />
                    <Column title="使用量" dataIndex="bidCount" key="bidCount" />

                    <Column title="故障量" dataIndex="droppedCount" key="droppedCount" render={(value, record) => {
                        let count = 0;

                        const netWorkStatus = record.bidClientStatus ? record.bidClientStatus.split(";") : [];

                        netWorkStatus.forEach(item => {
                            let errorCount = parseInt(item.split(",")[1]);

                            if (errorCount > 0) {
                                count = count + 1;
                            }
                        });

                        return count;

                    }} />

                    <Column title="详情" width={250} dataIndex="bidClientStatus" key="bidClientStatus" render={(value, record) => value ? value.split(";").map(item => <div key={item}>
                        <Tag className="db drop-detail pointer" onClick={handleGoToBid(item.split(",")[0])} color={getColor(item.split(",")[1])}>{item.split(",")[0]} <i>{item.split(",")[1]}</i></Tag>
                    
                    </div>) : ''} /> 

                    <Column title="状态" dataIndex="status" key="status" render={
                        (text, record) => <Switch checkedChildren="可用" unCheckedChildren="不可用" checked={text == 0} onClick={handleToggleStatus(record.id)} />
                    } />
                    {/*<Column title="标号" dataIndex="bidNumber" key="bidNumber" />*/}
                    <Column title="供应商" dataIndex="partnerName" key="partnerName" />

                    <Column
                        title="操作"
                        key="action"
                        width={140}
                        render={(text, record) => (
                            <Space size="middle">
                                <p className="text-primary pointer" onClick={handleEditBtnClick(record.id)}>编辑</p>
                                {/*<p className="text-error">禁用</p>*/}
                                <p className="text-error pointer" onClick={handleDelBtnClick(record.id)}>删除</p>
                            </Space>
                        )}
                    />
                </Table>
            </div>

            <Modal
                title={editId ? '代理编辑' : '代理录入'}
                visible={modalVisible}
                onOk={handleModalOk}
                confirmLoading={modalConfirmLoading}
                onCancel={handleModalCancel}
                okText="确定"
                cancelText="取消"
                maskClosable={false}
            >
                <Form
                    form={formNew}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 17 }}
                    name="basic"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="主机地址"
                        name="host"
                        rules={[
                            { required: true, message: '请输入主机地址' },
                            {
                                pattern: /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g,
                                message: '主机IP地址格式不正确',
                            },
                        ]}
                    >
                        <Input autoComplete="off" maxLength={15} placeholder="输入主机IP地址" />
                    </Form.Item>

                    <Form.Item
                        label="端口号"
                        name="port"
                        rules={[
                            { required: true, message: '请输入端口号' },
                            {
                                pattern: /^\d{1,5}$/,
                                message: '端口号为1-5位数字'
                            },
                        ]}
                    >
                        <Input autoComplete="off" type="number" maxLength={5} placeholder="输入端口号" />
                    </Form.Item>

                    <Form.Item
                        label="用户名"
                        name="userName"
                    >
                        <Input autoComplete="off" maxLength={36} placeholder="输入用户名" />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                    >
                        <Input autoComplete="off" placeholder="输入密码" />
                    </Form.Item>

                    <Form.Item
                        label="容量"
                        name="capacity"
                        initialValue={1}
                        rules={[
                            { required: true, message: '请输入容量' },

                            {
                                pattern: /^\d{1,3}$/,
                                message: '请输入1-3位数字'
                            }
                        ]}
                    >
                        <Input autoComplete="off" type="number" maxLength={2} placeholder="输入容量" />
                    </Form.Item>

                    <Form.Item
                        label="供应商"
                        name="partnerName"
                        rules={[{ required: true, message: '请输入供应商' }]}
                    >
                        <Input autoComplete="off" maxLength={36} placeholder="输入供应商" />
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                title="添加飞猪代理"
                visible={modalFlyVisible}
                onOk={handleModalFlyOk}
                confirmLoading={modalConfirmLoading}
                onCancel={handleModalFlyCancel}
                okText="确定"
                cancelText="取消"
                maskClosable={false}
            >
                <Form
                    form={formFly}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 17 }}
                    name="basic"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="地区"
                        name="address"
                        initialValue=""
                    >
                        <Select
                            allowClear
                            placeholder="选择代理地区"
                        >
                            <Option value="">
                                所有
                            </Option>
                            {
                                appConfig.proxyFly.addresses.map(address => <Option key={address} value={address}>
                                    {address}
                                </Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="数量"
                        name="count"
                        rules={[{ required: true, message: '请输入代理数量' }]}
                    >
                        <Input type="number" placeholder="输入代理数量" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
        <a
            href="https://code-sprite.oss-cn-shanghai.aliyuncs.com/proxy.csv"
            className="demo-download-link">
            <Button  shape="round" type="primary">
                <CompassOutlined />
                示例下载
            </Button>
        </a>
    </div>;
};

export default withRouter(ProxyList);