import React, {useEffect, useState, Fragment} from 'react';

import { Layout } from 'antd';

import { withRouter } from "react-router";

import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, WindowsOutlined, NodeExpandOutlined, FileTextOutlined } from '@ant-design/icons';

import SiderMenu from "../../components/SiderMenu";

import Header from "../../components/Header";

import logo from '../../logo.svg';

import './index.less';

import {usePageViews} from "../../App";

import imgCircle from '../../assets/imgs/circle.svg';
import imgCircleSpec from '../../assets/imgs/circle-spec.svg';
import {apiOverview} from "../../api";

const { Title } = Typography;

// bidAssignedSize: 3               绑定机器的标书
// bidTotalSize: 3                  标书总数
// bidWinSize: 3                    中标标书量
// machineTotalCapacity: 16         机器总容量
// machineTotalSize: 4              机器的数量
// proxyAssignedCapacity: 3         代理使用量
// proxyTotalCapacity: 4            代理总容量
// proxyTotalSize: 4                代理总数量

const Home = (props) => {

    const [bidTotalSize, setBidTotalSize] = useState(0);

    const [bidWinSize, setBidWinSize] = useState(0);

    const [bidAssignedSize, setBidAssignedSize] = useState(0);

    const [machineTotalCapacity, setMachineTotalCapacity] = useState(0);

    const [machineTotalSize, setMachineTotalSize] = useState(0);

    const [proxyAssignedCapacity, setProxyAssignedCapacity] = useState(0);

    const [proxyTotalCapacity, setProxyTotalCapacity] = useState(0);

    const [proxyTotalSize, setProxyTotalSize] = useState(0);

    usePageViews();

    useEffect(() => {
        getOverview();
    }, []);

    const getOverview = async () => {
        const res = await apiOverview();

        // console.log(res);

        const { bidTotalSize, bidWinSize, bidAssignedSize, machineTotalCapacity, machineTotalSize, proxyAssignedCapacity, proxyTotalCapacity, proxyTotalSize } = res.data;

        setBidTotalSize(bidTotalSize);

        setBidWinSize(bidWinSize);

        setBidAssignedSize(bidAssignedSize);

        setMachineTotalCapacity(machineTotalCapacity);

        setMachineTotalSize(machineTotalSize);

        setProxyAssignedCapacity(proxyAssignedCapacity);

        setProxyTotalCapacity(proxyTotalCapacity);

        setProxyTotalSize(proxyTotalSize);
    }

    const getMax = function () {

    }

    return <div className="page-home">
        <Header>
            <Breadcrumb>
                <Breadcrumb.Item href="/#/">
                    <HomeOutlined />
                    <span style={{marginLeft: 8}}>首页</span>
                </Breadcrumb.Item>
                {/*<Breadcrumb.Item href="">*/}
                {/*    <UserOutlined />*/}
                {/*    <span>Application List</span>*/}
                {/*</Breadcrumb.Item>*/}
                {/*<Breadcrumb.Item>Application</Breadcrumb.Item>*/}
            </Breadcrumb>
        </Header>

        <div className="content-panel-common">

            <div className="flex flex-wrap">

                <div className="card ">
                    <p className="card-title fs-16" >
                        <FileTextOutlined style={{marginRight: 10}} />
                        标书状态
                    </p>

                    <div className="content">
                        <div>
                            <div className="flex items-center">
                                <p className="label">总量:</p>
                                <div className="bid-bar primary"></div>
                                <p>
                                    {bidTotalSize}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <p className="label">已中标:</p>
                                <div className="bid-bar success" style={{width: bidWinSize / bidTotalSize * 300}}></div>
                                <p>
                                    {bidWinSize}
                                </p>
                            </div>
                            {
                                bidTotalSize && <Fragment>
                                    <div className="flex items-center">
                                        <p className="label">已分配机器:</p>
                                        <div className="bid-bar success" style={{width: bidAssignedSize / bidTotalSize * 300}}></div>
                                        <p>{bidAssignedSize}</p>
                                    </div>
                                    <div className="flex items-center">
                                        <p className="label">已分配代理:</p>
                                        <div className="bid-bar error" style={{width: proxyAssignedCapacity / bidTotalSize * 300}}></div>
                                        <p>{proxyAssignedCapacity}</p>
                                    </div>
                                </Fragment>
                            }

                        </div>
                    </div>
                </div>

                <div className="card ">
                    <p className="card-title fs-16" >
                        <WindowsOutlined style={{marginRight: 10}} />
                        机器状态
                    </p>

                    <div className="content">
                        {
                            machineTotalCapacity && <div>
                                <div className="flex items-center">
                                    <p className="label">数量:</p>
                                    <div className="bid-bar primary" style={{width: machineTotalSize / machineTotalCapacity * 300}}></div>
                                    <p>
                                        {machineTotalSize}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <p className="label">总容量:</p>
                                    <div className="bid-bar success" ></div>
                                    <p>{machineTotalCapacity}</p>
                                </div>
                                <div className="flex items-center">
                                    <p className="label">已分配容量:</p>
                                    <div className="bid-bar error" style={{width: bidAssignedSize / machineTotalCapacity * 300}}></div>
                                    <p>{bidAssignedSize}</p>
                                </div>
                            </div>
                        }

                    </div>

                </div>

                <div className="card ">
                    <p className="card-title fs-16" >
                        <NodeExpandOutlined style={{marginRight: 10}} />
                        代理状态
                    </p>

                    <div className="content">
                        {
                            proxyTotalCapacity && <div>
                                <div className="flex items-center">
                                    <p className="label">数量:</p>
                                    <div className="bid-bar primary" style={{width: proxyTotalSize / proxyTotalCapacity * 300}}></div>
                                    <p>
                                        {proxyTotalSize}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <p className="label">总容量:</p>
                                    <div className="bid-bar success" ></div>
                                    <p>{proxyTotalCapacity}</p>
                                </div>
                                <div className="flex items-center">
                                    <p className="label">已分配容量:</p>
                                    <div className="bid-bar error" style={{width: proxyAssignedCapacity / proxyTotalCapacity* 300}}></div>
                                    <p>{proxyAssignedCapacity}</p>
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>

        </div>
    </div>;
};

export default withRouter(Home);
