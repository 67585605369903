
import React, { useEffect, useState } from 'react';

import {Form, Row, Col, Input, Button, Table, Tag, Space, Breadcrumb, Menu, Modal, Select, message} from 'antd';

import './index.less';
import Header from "../../components/Header";
import { HomeOutlined, DownOutlined, UpOutlined, TeamOutlined, ProjectOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {usePageViews} from "../../App";
import {apiPartnerAdd, apiPartnerDel, apiPartnerList, apiPartnerUpdate} from "../../api/partner";
import {apiBidAdd, apiBidDel, apiBidDELETE, apiBidUpdate} from "../../api/bid";
// import {apiWorkerList} from "../../api";

const { Column, ColumnGroup } = Table;

const WorkerList = () => {

    usePageViews();

    useEffect(() => {
        getPartnerList({
            name: '',
            pageIndex: 1,
        });
    }, []);

    const [tableData, setTableData] = useState([]);

    const [expand, setExpand] = useState(false);

    const [form] = Form.useForm();

    const [formNew] = Form.useForm();

    const [editId, setEditId] = useState('');

    const [pageIndex, setPageIndex] = useState(1);

    const [total, setTotal] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

    useEffect(() => {

        if (!editId) {
            return;
        }

        const {name} = tableData.find(item => item.id == editId);

        formNew.setFieldsValue({
            name,
        });

    }, [editId]);

    const getPartnerList = async ({
        name,
        pageIndex = 1,
    }) => {
        const res = await apiPartnerList({
            name,
            pageIndex
        });

        setPageIndex(res.data.pageIndex);
        setTotal(res.data.total);

        console.log(res);

        setTableData(res.data.lists.map((item, index) => ({...item, key: index})));
    };

    const onFinish = ({name = ''}) => {
        getPartnerList({
            name: name.trim(),
            pageIndex: 1,
        });
    };

    const pageChange = (page) => {
        const name = form.getFieldValue('name') || '';

        getPartnerList({
            name: name.trim(),
            pageIndex: page,
        });
    };

    const handleModalOk = async () => {
        try {
            const { name } = await formNew.validateFields();

            setModalConfirmLoading(true);

            if (editId) {
                await apiPartnerUpdate({
                    id: editId,
                    name,
                });
            } else {
                await apiPartnerAdd({
                    name,
                });
            }

            formNew.resetFields();
            setModalVisible(false);
            setModalConfirmLoading(false);

            message.success(`友商${editId ? '更新' : '添加'}成功`);

            setEditId(null);

            getPartnerList({
                pageIndex: 1,
            });

        } catch (e) {
            message.error(e.message);
            setModalConfirmLoading(false);
        }
    };

    const handleModalCancel = () => {
        setEditId(null);
        setModalVisible(false);
        formNew.resetFields();
    };

    const handleBtnNewClick = () => {
        setModalVisible(true);
    };

    const handleEditBtnClick = (id) => async () => {
        console.log(id);

        // const res = await apiBidUpdate()
        //

        setEditId(id);

        setModalVisible(true);
    };

    const handleDelBtnClick = (id) => async () => {

        Modal.confirm({
            title: '删除友商',
            icon: <ExclamationCircleOutlined />,
            content: '是否要删除该友商?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            async onOk() {
                await apiPartnerDel({id});

                message.success("友商已删除");

                pageChange(pageIndex);
            },
            // onCancel() {
            // },
        });

    };

    return <div className="page-home">
        <Header>
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <TeamOutlined />
                        <span>友商管理</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div>
                    <Button
                        style={{margin: '0 15px'}}
                        shape="round"
                        icon={<PlusOutlined />}
                        onClick={handleBtnNewClick}
                    >
                        新增友商
                    </Button>
                </div>
            </div>

        </Header>

        <div className="content-panel-common">
            <div className="filter-pannel-common">
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="name"
                                label="昵称"
                            >
                                <Input placeholder="输入友商昵称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                onClick={() => {
                                    form.resetFields();
                                    getPartnerList({pageIndex: 1});
                                }}
                            >
                                重置
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div >
                <Table
                    // loading={true}
                    pagination={{
                        defaultCurrent: 1,
                        current: pageIndex,
                        total,
                        showSizeChanger: false,
                        pageSize: 10,
                        onChange: pageChange,
                    }}
                    dataSource={tableData}
                >
                    <Column title="友商ID" dataIndex="id" key="id" />
                    <Column title="昵称" dataIndex="name" key="name" />
                    <Column
                        title="友商头像"
                        dataIndex="avatarUrl"
                        render={
                            (text, record) => (<img src={text} width={80} />)
                        }
                    />

                    <Column title="标书数" dataIndex="totalHit" key="totalHit" />

                    <Column title="中标数" dataIndex="totalHit" key="totalHit" />

                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <Space size="middle">
                                <p className="text-primary pointer" onClick={handleEditBtnClick(record.id)}>编辑</p>
                                <p className="text-error pointer" onClick={handleDelBtnClick(record.id)}>删除</p>
                            </Space>
                        )}
                    />
                </Table>
            </div>
        </div>

        <Modal
            title={editId ? '友商编辑' : '新增友商'}
            visible={modalVisible}
            onOk={handleModalOk}
            confirmLoading={modalConfirmLoading}
            onCancel={handleModalCancel}
            okText="确定"
            cancelText="取消"
            maskClosable={false}
        >
            <Form
                form={formNew}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 17 }}
                name="basic"
                // onFinish={onFinish}
            >
                <Form.Item
                    label="昵称"
                    name="name"
                    rules={[{ required: true, message: '请输入友商昵称' }]}
                >
                    <Input maxLength={20} />
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label="手机号"*/}
                {/*    name="phone"*/}
                {/*    rules={[{ required: true, message: '请输入友商手机号' }]}*/}
                {/*>*/}
                {/*    <Input />*/}
                {/*</Form.Item>*/}

            </Form>
        </Modal>

    </div>;
};

export default WorkerList;