// 代理管理接口
import $fetch from "./fetch";

// 获取系统配置项
export const apiGetSetting = () => {
    return $fetch('/system/setting', {
        method: 'GET',
        params: {}
    });
};

// 设置系统配置项
export const apiSettingUpdate =
    ({
         loginUrl,
         enableProxy,
         circleProxy,
         production,
         autocode,
         fakecode,
         takeover,
         running,
         delay,
         coders,
         consoleVersion,
         clientVersion,
         bidder,
     }) => {
    return $fetch('/system/setting', {
        method: 'PUT',
        params: {
            loginUrl,
            enableProxy,
            circleProxy,
            production,
            autocode,
            fakecode,
            takeover,
            running,
            delay,
            coders,
            consoleVersion,
            clientVersion,
            bidder,
        }
    });
}

// 推送配置项
export const apiSettingAction = () => {
    return $fetch('/system/setting/action', {
        method: 'POST',
        params: {}
    });
}