import React, { useEffect, useState, useRef } from 'react';
import { HomeOutlined, StockOutlined } from '@ant-design/icons';
import {Breadcrumb, Button} from 'antd';
import Header from '../../components/Header';
import './index.less';
import {Chart} from '@antv/g2';

const Charts = () => {

    const inputFile = useRef(null);

    const [chartData, setChartData] = useState([]);


    useEffect(() => {

    }, []);

    const handleFileChange = (e) => {
        const file = inputFile.current.files[0];
        
        if (!file) {
            return;
        }

        document.getElementById('chart').innerHTML = '';

        const reader = new FileReader();

        reader.readAsText(file, 'utf-8');

        reader.onload = function () {
            const _data = mapRes(this.result);

            _data.forEach((item) => {
                const title = document.createElement('div');
                title.innerHTML = `<p class="chart-title">日期 ${item.month}</p>`;

                document.getElementById('chart').appendChild(title);

                const chart = new Chart({ 
                    paddingLeft: 60, 
                    paddingRight: 60,
                    container: 'chart',
                    width: 1400,
                    height: 600,
                });
    
                // const xy = (node) =>
                //     node.encode('x', (d) => new Date(d.time)).encode('y', 'delay');
    
                chart.data(item.data);

                chart.axis('delay', {
                    label: {
                      formatter: (val) => {
                        return val + ' ms';
                      },
                    },
                  });
    
                chart.line().position('time*delay').color('bid');

                // chart.line().position('time*min').color('#9AD681').shape('dash');

                // chart.line().position('time*mean').color('#0c8cfa');

                chart.render();

            });
            
        }
    };

    const mapRes = (result) => {
        const _res = result.split('\n');

        if (!_res[0].match(/\d/))　{
            _res.shift();
        }

        // '326,58135936,T11:27:13.046,T11:27:13.000,46\r'
        // 根据月份拆分图表
        // 根据标书拆分折线图表

        let _data = [];

        _res.forEach(item => {
            const [month, bid, timeFinal, time, delay] = item.replace('\r', '').split(',');

            const _index = _data.findIndex(_it => _it.month === month);

            const _time = time.match(/\d*:(\d*):(\d*)/);

            if (_time) {
                // 最后 30s 
                if (_time[1] == 29 && (_time[2] > 29)) {
                    if (_index === -1) {
                        _data.push({
                            month,
                            data: [
                                {
                                    month, 
                                    bid, 
                                    timeFinal, 
                                    time, 
                                    delay: parseInt(delay)
                                }
                            ],
                        });
                    } else {
                        _data[_index].data.push({
                            month, 
                            bid, 
                            timeFinal, 
                            time, 
                            delay: parseInt(delay)
                        });
                    }
                }
            }

   
        });

        return _data;

    };



    // const mapRes = (result) => {
    //     const _res = result.split('\n');

    //     if (!_res[0].match(/\d/))　{
    //         _res.shift();
    //     }

    //     // '326,58135936,T11:27:13.046,T11:27:13.000,46\r'
    //     // 根据月份拆分图表
    //     // 根据标书拆分折线图表

    //     let _data = [];

    //     _res.forEach(item => {
    //         const [month, bid, timeFinal, time, delay] = item.replace('\r', '').split(',');

    //         const _index = _data.findIndex(_it => _it.month === month);

    //         const _time = time.match(/\d*:(\d*):(\d*)/);

    //         if (_time) {
    //             // 最后 30s 
    //             if (_time[1] == 29 && (_time[2] > 29)) {
    //                 if (_index === -1) {
    //                     _data.push({
    //                         month,
    //                         data: [
    //                             {
    //                                 month, 
    //                                 bid, 
    //                                 timeFinal, 
    //                                 time, 
    //                                 delay: parseInt(delay)
    //                             }
    //                         ],
    //                     });
    //                 } else {
    //                     _data[_index].data.push({
    //                         month, 
    //                         bid, 
    //                         timeFinal, 
    //                         time, 
    //                         delay: parseInt(delay)
    //                     });
    //                 }
    //             }
    //         }

   
    //     });

    //     return _data;

    // };

    // const mapRes = (result) => {
    //     const _res = result.split('\n');

    //     if (!_res[0].match(/\d/))　{
    //         _res.shift();
    //     }

    //     // '326,58135936,T11:27:13.046,T11:27:13.000,46\r'
    //     // 根据月份拆分图表
    //     // 根据标书拆分折线图表

    //     // 按日期分组，然后统计每个点的最大、最小、均值、中位数

    //     let _data = [];

    //     _res.forEach(item => {
    //         const [month, bid, timeFinal, time, delay] = item.replace('\r', '').split(',');

    //         const _index = _data.findIndex(_it => _it.month === month);

    //         const _time = time.match(/\d*:(\d*):(\d*)/);

    //         if (_time && (delay < 750)) {
    //             // 最后 30s 
    //             if (_time[1] == 29 && (_time[2] > 29)) {
    //                 if (_index === -1) {
    //                     _data.push({
    //                         month,
    //                         data: [
    //                             {
    //                                 time, 
    //                                 delayData: [parseInt(delay)]
    //                             }
    //                         ],
    //                     });
    //                 } else {
    //                     const _timeIndex =_data[_index].data.findIndex(_it => _it.time === time)
    //                     if (_timeIndex === -1) {
    //                         _data[_index].data.push({
    //                             time, 
    //                             delayData: [parseInt(delay)]
    //                         });
    //                     } else {
    //                         _data[_index].data[_timeIndex].delayData.push(parseInt(delay));
    //                     }
    //                 }
    //             }
    //         }
   
    //     });

    //     console.log(_data);

    //     _data = _data.map(item => {


    //         // 根据值，统计每个点的最大、最小、均值、中位数

    //         const data = item.data.map(_it => {
    //             const _delayData = _it.delayData;

    //             const max = Math.max(..._delayData);
         
    //             const min = Math.min(..._delayData);
         
    //             const mean = _delayData.reduce((a, b) => a + b) / _delayData.length;

    //             if (max < min) {
    //                 console.log(_it.delayData);
    //             }
         
    //             return {
    //                 time: _it.time,
    //                 max,
    //                 min,
    //                 mean,
    //             }

    //         });

    //         return {
    //             month: item.month,
    //             data,
    //         };
    
    //     });

    //     return _data;

    // };

    return <div className="page-home page-charts">
        <Header>
            <div className="flex justify-between">

                <Breadcrumb>
                    <Breadcrumb.Item href="/#/">
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="">
                        <StockOutlined />
                        <span>图表</span>
                    </Breadcrumb.Item>
                </Breadcrumb>

            </div>

        </Header>

        <div className="content-panel-common">
            {/* <div>服务器信息延迟图表</div> */}
            <div>
                <input ref={inputFile} className="btn-upload" type="file" onChange={handleFileChange} />
            </div>
            <div id="chart">

            </div>
        </div>
    </div>;
};

export default Charts;